const jilli7="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563940/jilli7_devqz4.png"
const jilli1="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563908/jilli1_oi9vgj.png"
const jilli6="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563940/jilli6_fp3lww.png"
const jilli4="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563940/jilli4_lgrg5i.png"
const jilli5="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563940/jilli5_yqe38z.png"
const jilli3="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563930/jilli3_c5mnwb.png"
const jilli2="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563908/jilli2_aeyuk5.png"
const fishing1="https://res.cloudinary.com/dx1fouxno/image/upload/v1726563895/fishing1_epbbie.png"


const casino61= "https://i.ibb.co/7GxmJrs/casino61.png"
const casino62= "https://i.ibb.co/2cfvhSV/casino62.png"
const casino63= "https://i.ibb.co/khm9cSN/casino63.png"
const casino111= "https://i.ibb.co/X5jXnpb/casino111.png"
const casino112= "https://i.ibb.co/jrbgrtc/casino112.png"
const casino113= "https://i.ibb.co/zfTfSJ1/casino113.png"
const casino118= "https://i.ibb.co/hFP3SLx/casino118.png"
const casino122= "https://i.ibb.co/5cQb7Xw/casino122.png"
const casino123= "https://i.ibb.co/J3MyV0x/casino123.png"
const casino124= "https://i.ibb.co/2yYJ24F/casino124.png"
const casino125= "https://i.ibb.co/BqRjJTJ/casino125.png"
const casino139= "https://i.ibb.co/L6TdFck/casino139.png"
const casino148= "https://i.ibb.co/wBfqcPj/casino148.png"
const casino149= "https://i.ibb.co/cCdMjKX/casino149.png"
const casino150= "https://i.ibb.co/vBXR0MZ/casino150.png"
const casino151= "https://i.ibb.co/qFVbjrs/casino151.png"
const casino152= "https://i.ibb.co/KKSxkw3/casino152.png"
const casino173= "https://i.ibb.co/tQwY3b2/casino173.png"
const casino177= "https://i.ibb.co/rMQY9L1/casino177.png"
const casino178= "https://i.ibb.co/mFZmCVJ/casino178.png"
const casino179= "https://i.ibb.co/q0bkN9Z/casino179.png"
const casino182= "https://i.ibb.co/pLwrHCB/casino182.png"
const casino195= "https://i.ibb.co/jLpFmRs/casino195.png"
const casino197= "https://i.ibb.co/DVqDg2s/casino197.png"
const casino200= "https://i.ibb.co/4Ym1fSs/casino200.png"
const casino204= "https://i.ibb.co/pyrXHs2/casino204.png"
const casino216= "https://i.ibb.co/Lhq95CB/casino216.png"
const casino217= "https://i.ibb.co/b1m6BBj/casino217.png"
const crash224= "https://i.ibb.co/cT5pwMf/crash224.png"
const crash229= "https://i.ibb.co/cxkNBV8/crash229.png"
const crash232= "https://i.ibb.co/HYL3LbY/crash232.png"
const crash233= "https://i.ibb.co/ZNxY4jB/crash233.png"
const crash235= "https://i.ibb.co/6WHDqbb/crash235.png"
const crash236= "https://i.ibb.co/rHPVYdY/crash236.png"
const crash241= "https://i.ibb.co/LnDD7cb/crash241.png"
const crash242= "https://i.ibb.co/PWLzsSj/crash242.png"
const crash254= "https://i.ibb.co/tMN12D5/crash254.png"
const crash261= "https://i.ibb.co/0DdP0zY/crash261.png"

const fishing20= "https://i.ibb.co/HP067dP/fishing20.png"
const fishing32= "https://i.ibb.co/9Y8rJSr/fishing32.png"
const fishing42= "https://i.ibb.co/8x2n7y8/fishing42.png"
const fishing60= "https://i.ibb.co/RzPR2TG/fishing60.png"
const fishing71= "https://i.ibb.co/1LKxymg/fishing71.png"
const fishing74= "https://i.ibb.co/5Yx2vq3/fishing74.png"
const fishing119= "https://i.ibb.co/9nMTHgQ/fishing119.png"
const fishing212= "https://i.ibb.co/9n2Hftx/fishing212.png"
const fishing882= "https://i.ibb.co/sjGqLGx/fishing882.png"

const rummy72= "https://i.ibb.co/WcMmG3T/rummy72.png"
const rummy75= "https://i.ibb.co/5TrZ36X/rummy75.png"
const rummy79= "https://i.ibb.co/tLrRTZ4/rummy79.png"
const rummy94= "https://i.ibb.co/dbkKBJp/rummy94.png"
const rummy127= "https://i.ibb.co/DM9J6Xj/rummy127.png"
const rummy159= "https://i.ibb.co/CHPnXk2/rummy159.png"
const rummy160= "https://i.ibb.co/NCjq0wx/rummy160.png"
const rummy211= "https://i.ibb.co/MDmdy2W/rummy211.png"
const rummy219= "https://i.ibb.co/1dkWpL2/rummy219.png"
const rummy220= "https://i.ibb.co/Yd70vks/rummy220.png"
const rummy221= "https://i.ibb.co/qxdW141/rummy221.png"
const rummy231= "https://i.ibb.co/898SwbM/rummy231.png"
const rummy253= "https://i.ibb.co/SJ0x6Sn/rummy253.png"
const slot2= "https://i.ibb.co/SP7n9Kb/slot2.png"
const slot6= "https://i.ibb.co/g9fSwTZ/slot6.png"
const slot7= "https://i.ibb.co/NKQCS0w/slot7.png"
const slot8= "https://i.ibb.co/D9dBbg5/slot8.png"
const slot9= "https://i.ibb.co/QPSXQMy/slot9.png"
const slot16= "https://i.ibb.co/rHWKhVn/slot16.png"
const slot17= "https://i.ibb.co/smzSsbW/slot17.png"
const slot21= "https://i.ibb.co/NZD1t1F/slot21.png"
const slot23= "https://i.ibb.co/JKpXbGQ/slot23.png"
const slot27= "https://i.ibb.co/C9J6cDn/slot27.png"
const slot30= "https://i.ibb.co/hXknxSd/slot30.png"
const slot33= "https://i.ibb.co/ZzP0y4h/slot33.png"
const slot35= "https://i.ibb.co/g3CMXxb/slot35.png"
const slot36= "https://i.ibb.co/yB6vcpg/slot36.png"
const slot38= "https://i.ibb.co/bRmr9Ck/slot38.png"
const slot40= "https://i.ibb.co/Y7Hz3v6/slot40.png"
const slot43= "https://i.ibb.co/VH129kC/slot43.png"
const slot45= "https://i.ibb.co/DWZpPk8/slot45.png"
const slot46= "https://i.ibb.co/XYDRShJ/slot46.png"
const slot47= "https://i.ibb.co/DDbfQPy/slot47.png"
const slot48= "https://i.ibb.co/LgL83W8/slot48.png"
const slot49= "https://i.ibb.co/ssN3NrN/slot49.png"
const slot51= "https://i.ibb.co/xz00dvn/slot51.png"
const slot58= "https://i.ibb.co/64mRpR0/slot58.png"
const slot66= "https://i.ibb.co/KVsLNrp/slot66.png"
const slot76= "https://i.ibb.co/mDzcmwV/slot76.png"
const slot77= "https://i.ibb.co/2Zv7rXh/slot77.png"
const slot78= "https://i.ibb.co/Sd5h5p6/slot78.png"
const slot85= "https://i.ibb.co/rbP7DZG/slot85.png"
const slot87= "https://i.ibb.co/933DkkC/slot87.png"
const slot91= "https://i.ibb.co/6rJgpWW/slot91.png"
const slot92= "https://i.ibb.co/SV3jzZ9/slot92.png"
const slot100= "https://i.ibb.co/t2Vp421/slot100.png"
const slot101= "https://i.ibb.co/CV7psnw/slot101.png"
const slot102= "https://i.ibb.co/3RSd3Nr/slot102.png"
const slot103= "https://i.ibb.co/Nj5yn5C/slot103.png"
const slot106= "https://i.ibb.co/jwZb5C0/slot106.png"
const slot108= "https://i.ibb.co/GMFxFdk/slot108.png"
const slot109= "https://i.ibb.co/xfrcM77/slot109.png"
const slot110= "https://i.ibb.co/rZ450cJ/slot110.png"
const slot115= "https://i.ibb.co/Sym0BmZ/slot115.png"
const slot116= "https://i.ibb.co/nMZfGR0/slot116.png"
const slot126= "https://i.ibb.co/QcdChYc/slot126.png"
const slot130= "https://i.ibb.co/Bny6D9n/slot130.png"
const slot134= "https://i.ibb.co/GTmjKg8/slot134.png"
const slot135= "https://i.ibb.co/TbnYnqV/slot135.png"
const slot136= "https://i.ibb.co/HChMWH9/slot136.png"
const slot137= "https://i.ibb.co/RNP66B5/slot137.png"
const slot142= "https://i.ibb.co/6PsfFY2/slot142.png"
const slot144= "https://i.ibb.co/R200Mz4/slot144.png"
const slot145= "https://i.ibb.co/dfZbvTQ/slot145.png"
const slot146= "https://i.ibb.co/v34GvH5/slot146.png"
const slot153= "https://i.ibb.co/fQrjS7Q/slot153.png"
const slot164= "https://i.ibb.co/47qC0JB/slot164.png"
const slot166= "https://i.ibb.co/G2jQN5r/slot166.png"
const slot171= "https://i.ibb.co/tsKzYGZ/slot171.png"
const slot172= "https://i.ibb.co/Rg6z5W0/slot172.png"
const slot176= "https://i.ibb.co/HP5gp0X/slot176.png"
const slot181= "https://i.ibb.co/xz9YdcJ/slot181.png"
const slot183= "https://i.ibb.co/fvBsLH3/slot183.png"
const slot191= "https://i.ibb.co/6FXSc16/slot191.png"
const slot193= "https://i.ibb.co/MZJ7r7R/slot193.png"
const slot198= "https://i.ibb.co/8NvmPv7/slot198.png"
const slot209= "https://i.ibb.co/3rmvH37/slot209.png"
const slot214= "https://i.ibb.co/qmfBQXt/slot214.png"
const slot223= "https://i.ibb.co/1zhFHHX/slot223.png"
const slot225= "https://i.ibb.co/CH8Mfbz/slot225.png"
const slot230= "https://i.ibb.co/rmmTt0x/slot230.png"
const slot238= "https://i.ibb.co/kHpTSVq/slot238.png"
const slot239= "https://i.ibb.co/pXZgvK4/slot239.png"
const slot252= "https://i.ibb.co/nzjYFr0/slot252.png"
const slot301= "https://i.ibb.co/x5bD9MP/slot301.png"
const slot303= "https://i.ibb.co/kS75qWM/slot303.png"


export const Casino=[
    casino217,
    casino216,
    casino204,
    casino197,
    casino200,
    casino195,
    casino182,
    casino179,
    casino178,
    casino173,
    casino177,
    casino151,
    casino152,
    casino150,
    casino149,
    casino148,
    casino111,
    casino125,
    casino139,
    casino112,
    casino118,
    casino113,
    casino124,
    casino123,
    casino61,
    casino63,
    casino122,
    casino62

]
export const Crash=[
    crash261,
    crash254,
    crash242,
    crash241,
    crash236,
    crash235,
    crash232,
    crash233,
    crash229,
    crash224
]
export const Fishing=[
fishing882,
fishing212,
fishing119,
fishing1,
fishing60,
fishing74,
fishing71,
fishing42,
fishing32,
fishing20
]
export const Jilli=[
     jilli7,
     jilli1,
     jilli6,
     jilli4,
     jilli5,
     jilli3,
     jilli2
]
export const Rummy=[
    rummy253,
    rummy220,
    rummy211,
    rummy231,
    rummy221,
    rummy219,
    rummy160,
    rummy159,
    rummy127,
    rummy75,
    rummy94,
    rummy79,
    rummy72
]
export const Slots=[
    slot303,
    slot301,
    slot252,
    slot238,
    slot239,
    slot230,
    slot225,
    slot223,
    slot214,
    slot209,
    slot193,
    slot198,
    slot183,
    slot181,
    slot191,
    slot176,
    slot172,
    slot171,
    slot166,
    slot164,
    slot146,
    slot153,
    slot144,
    slot145,
    slot137,
    slot142,
    slot136,
    slot135,
    slot126,
    slot130,
    slot134,
    slot115,
    slot116,
    slot109,
    slot110,
    slot108,
    slot103,
    slot106,
    slot102,
    slot100,
    slot101,
    slot92,
    slot91,
    slot87,
    slot85,
    slot78,
    slot77,
    slot51,
    slot66,
    slot76,
    slot58,
    slot49,
    slot48,
    slot47,
    slot46,
    slot40,
    slot43,
    slot45,
    slot38,
    slot36,
    slot35,
    slot33,
    slot27,
    slot30,
    slot23,
    slot17,
    slot21,
    slot16,
    slot9,
    slot8,
    slot7,
    slot6,
    slot2
]