import Avatar1 from "../../assets/avatar1.png"
import Avatar2 from "../../assets/avatar2.png"
import Avatar3 from "../../assets/avatar3.png"
import Avatar4 from "../../assets/avatar4.png"
import Avatar5 from "../../assets/avatar5.png"
import Avatar6 from "../../assets/avatar6.png"
import Avatar7 from "../../assets/avatar7.png"
import Avatar8 from "../../assets/avatar8.png"
import Avatar9 from "../../assets/avatar9.png"
import Avatar10 from "../../assets/avatar10.png"
import Avatar11 from "../../assets/avatar11.png"
import Avatar12 from "../../assets/avatar12.png"
import Avatar13 from "../../assets/avatar13.png"
import Avatar14 from "../../assets/avatar14.png"
import Avatar15 from "../../assets/avatar15.png"
import Avatar16 from "../../assets/avatar16.png"
import Avatar17 from "../../assets/avatar17.png"
import Avatar18 from "../../assets/avatar18.png"
import Avatar19 from "../../assets/avatar19.png"
import Avatar20 from "../../assets/avatar20.png"

import VIP0 from "../../assets/vip.png"
import VIP1 from "../../assets/vip1.png"
import VIP2 from "../../assets/vip2.png"
import VIP3 from "../../assets/vip3.png"
import VIP4 from "../../assets/vip4.png"
import VIP5 from "../../assets/vip5.png"
import VIP6 from "../../assets/vip6.png"
import VIP7 from "../../assets/vip7.png"
import VIP8 from "../../assets/vip8.png"
import VIP9 from "../../assets/vip9.png"
import VIP10 from "../../assets/vip10.png"


export const VIPImg ={
   0:VIP0,
   1:VIP1,
   2:VIP2,
   3:VIP3,
   4:VIP4,
   5:VIP5,
   6:VIP6,
   7:VIP7,
   8:VIP8,
   9:VIP9,
   10:VIP10,
}

export const AvatarData=
   {
      0: Avatar1,
    1: Avatar2,
    2: Avatar3,
    3: Avatar4,  // Use Avatar4 for index 3 and so on
    4: Avatar5,
    5: Avatar6,
    6: Avatar7,
    7: Avatar8,
    8: Avatar9,
    9: Avatar10,
    10: Avatar11,
    11: Avatar12,
    12: Avatar13,
    13: Avatar14,
    14: Avatar15,
    15: Avatar16,
    16: Avatar17,
    17: Avatar18,
    18: Avatar19,
    19: Avatar20,
    
   }
