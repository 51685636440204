import React,{useState} from "react";
import { RxCrossCircled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
const FirstPopup = ({ setTopup2 }) => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate=useNavigate()
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
    return(
        <>
        <div className={ "overlay-section block"}></div>
     
        <div id="popup" className="popup bg-color-l">
          <div className="header-section blue-linear">
            <h3>Extra first deposit bonus</h3>
            <p>Each account can only receive rewards once</p>
          </div>
          <div className="middle-content-section">
            <ul>
              {depositBonus?.map((item, i) => (
                <li key={i} onClick={() => navigate("/wallet/Recharge")}>
                  <div className="first-c">
                    <p className="gray-50">First deposit <span className="color-blue">{item.deposit.toLocaleString()}</span></p>
                    <p className="color-blue">+₹{item.bonus.toLocaleString()}.00</p>
                  </div>
                  <p className="color-gray">Deposit {item.deposit.toLocaleString()} for the first time in your account and you can receive
                    {item.deposit + item.bonus.toLocaleString()}</p>
                  <div className="bottom-c">
                    <div className="slider-box">0/{item.deposit.toLocaleString()}</div>
                    <button className="border">Deposit</button>
                  </div>
                </li>
              ))}

            </ul>
          </div>
          <div className="bottom-section">
            <div>

              <label className="flex items-center ">
                <input
                  type="checkbox"
                  className="hidden peer"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <div className="w-6 h-6 rounded-full border-2 border-gray-300 flex items-center justify-center peer-checked:border-blue-500 peer-checked:bg-blue-500">
                  <svg
                    className={`w-4 h-4 text-white ${isChecked ? 'block' : 'hidden'}`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-7.5 7.5a1 1 0 01-1.414 0l-3.5-3.5a1 1 0 111.414-1.414L8 11.586l6.793-6.793a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <span className="gray-100 ms-2 mr-2 text-sm cursor-pointer">No more reminders today</span>
              </label>
            </div>
            <button className="activity blue-linear" onClick={() => setTopup2(false)}>Activity</button>
          </div>
          <span onClick={() => setTopup2(false)}>
            <RxCrossCircled className="m-auto flex text-center absolute left-0 right-0 justify-center text-2xl mt-4 " />
          </span>
        </div>
 
        </>
    )
}


const depositBonus = [
    {
      deposit: 100000,
      bonus: 5888,
    },
    {
      deposit: 50000,
      bonus: 2888,
    },
    {
      deposit: 10000,
      bonus: 488,
    },
    {
      deposit: 5000,
      bonus: 288,
    },
    {
      deposit: 1000,
      bonus: 188,
    },
    {
      deposit: 500,
      bonus: 108,
    },
    {
      deposit: 300,
      bonus: 48,
    },
  ]
  
export default FirstPopup
